import React from "react";
import "./service.css";
// import laptopimg from "../img/laptop.jpg";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SpatialAudioOffOutlinedIcon from "@mui/icons-material/SpatialAudioOffOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";




const Service = () => {
  return (
    <div>
      <div className="service">
        {/* <img src={laptopimg} alt="laptop" className="service-img" /> */}
        <div className="service-img parallax-fix"></div>
        <div className="service-section2">
          <div className="service-section-div" >
            <div>
              <p>
                <VideocamOutlinedIcon style={{ color: "#FDD947", fontSize: 30, alignSelf: "center" }} />
              </p>
              <span>VIDEO PRODUCTION</span>
              <p className="service-text">
                Our end-to-end production suite captures everything from Concept
                to production to marketing & distribution.{" "}
              </p>
            </div>
          </div>

          <div className="service-section-div" >
            <div>
              <p>
                <SpatialAudioOffOutlinedIcon
                  style={{ color: "#FDD947", fontSize: 30, alignSelf: "center" }}
                />
              </p>
              <span>AUDIO PRODUCTION </span>
              <p className="service-text">
                Leverage our voiceover artists, sound engineers, and music
                producers to add value to your content.
              </p>
            </div>
          </div>
          <div className="service-section-div" >
            <div>
              <p>
                <DesktopWindowsOutlinedIcon
                  style={{ color: "#FDD947", fontSize: 30, alignSelf: "center" }}

                />
              </p>
              <span>DIGITAL MARKETING </span>
              <p className="service-text">
                Let our expert team of media buyers, and\advertising consultants
                streamline and turbo-charge your marketing campaigns.
              </p>
            </div>
          </div>
          <div className="service-section-div" >
            <div>
              <p>
                <PermMediaOutlinedIcon
                  style={{ color: "#FDD947", fontSize: 30, alignSelf: "center" }}

                />
              </p>
              <span> MEDIA CURATION</span>
              <p className="service-text">
                Leverage and curate your existing content into viable products
                that can be distributed and monetized across the globe.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Service;
