    
import innovation from'./img/friends.jpg'
import Versatility from './img/silhoutte.jpg'
import Effective from './img/young-adults.jpg'
  
    
    
    
    
    
    export const homeData= [
    {
      
      imageSrc: Versatility,
      description: "Innovation & Creativity",
      statement: "Our mission is to always create new, unique content."
      
    },

    {
      
      imageSrc:  innovation,
      description: "Professionalism & Versatility",
      statement: "Expertly crafting and curate the content your brand needs."
      
    },

    {
      
      imageSrc: Effective,
      description: "Timeless & Effective",
      statement: "Memorable content that stands the test of time and audiences."
      
    }

  
  ]
