import "./about.css";
import React from 'react';
import CountUp from "react-countup";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';

const About = () => {
  return (
    <>
      <div className="about">
        <div className="about-title">ABOUT US</div>
        <div className="about-section">
          <div className="about-section1">
            <div className="intro-separator"></div>
            <p className="intro-text1">
              We've been creating beautiful content, launching stunning brands,
              and making our clients happy for over 10 years.
            </p>
            <p className="intro-text2">
              NoVex Africa is a boutique digital media agency, though based in
              Lagos Nigeria we work with brands in the US, UK, South Africa, and
              far beyond, with technology the only borders we have are in our
              content.
            </p>
            <button className="intro-btn">OUR SERVICES</button>
          </div>
          <div className="about-section2">
            <div>
              <p><AccessTimeIcon style={{ fontSize: 40, strokeWidth: 6, }} color="disabled" /></p>

              <span>Quick Delivery</span>

              <div id="line"><hr /></div>
              <p className="about-text">
                We will also meet your timelines and deadlines with our 24/7
                service.{" "}
              </p>
            </div>
            <div>
              <p ><GppGoodOutlinedIcon style={{ fontSize: 40 }} color="disabled" /></p>
              <span>High Quality </span>
              <div id="line"><hr /></div>
              <p className="about-text">
                We use the latest in technique, technology and equipment on
                every project.
              </p>
            </div>
            <div>
              <p ><SellOutlinedIcon style={{ fontSize: 40 }} color="disabled" /></p>
              <span>Flexible Budget </span>
              <div id="line"><hr /></div>
              <p className="about-text">
                We work to understand our clients needs and provide solutions to
                bring budget gaps.
              </p>
            </div>
            <div>
              <p><FavoriteBorderIcon style={{ fontSize: 40 }} color="disabled" /></p>
              <span> Reliable Service</span>
              <div id="line"><hr /></div>
              <p className="about-text">
                Our pride is in our method which ensures that we deliver by
                exceeding expectations 110% of the time
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="counter">
        <div>
          <span>
            <CountUp end={30} isCounting duration={3} />+
          </span>
          <p>happy client</p>
        </div>
        <div>
          <span>
            <CountUp end={200} isCounting duration={3} />+
          </span>
          <p>project completed</p>
        </div>
        <div>
          <span>
            $<CountUp start={19500} end={20000} isCounting duration={3} />+
          </span>
          <p>total marketing spend</p>
        </div>
      </div>
    </>
  );
};

export default About;
