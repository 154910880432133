import React from "react";
import "./portfolio.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaVimeoV } from "react-icons/fa";
import laptop from "../img/skill.jpg";
import filtered from "../img/Portfolio Images/uNfiltered_1.png";
import face from "../img/Portfolio Images/Face-2-Face-Nike-Art.png";
import lekanimg from "../img/Portfolio Images/Laycon.png";
import amakaimg from "../img/Portfolio Images/Igbo-Amaka.png";
import sable from "../img/Portfolio Images/Sable-Content.jpeg";
import edusesh from "../img/Portfolio Images/Edusessions Uganda_cover.png";
import fast_fast from "../img/Portfolio Images/Fast Fast App Cover.png";
import ndic from "../img/Portfolio Images/NDIC WET Cover.png";

const Portfolio = () => {
  return (
    <>
      <div className="portfolio">
        <h2 className="heading">PORTFOLIO</h2>
        <div className="gallerysection">
          <div className="gallery">
            <a href="https://www.youtube.com/watch?v=TFFbPn4xeNI" target="_blank" rel="noreferrer noopener" >
              <img src={filtered} alt="unfiltered" className="gallery_img" />

              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>

          <div className="gallery">
            <a href="https://www.youtube.com/watch?v=LrPiyrhH7Yo" target="_blank" rel="noreferrer noopener" >
              <img src={lekanimg} alt="layconbbn" className="gallery_img" />
              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>
          <div className="gallery">
            <a href="https://www.youtube.com/watch?v=7wugT-LinL0" target="_blank" rel="noreferrer noopener" >
              <img src={face} alt="art gallery" className="gallery_img" />

              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>
          <div className="gallery">
            <a href="https://www.youtube.com/watch?v=R3YqKTzr46g" target="_blank" rel="noreferrer noopener" >
              <img src={amakaimg} alt="Igbo Amaka" className="gallery_img" />

              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>
          <div className="gallery">
            <a href="https://youtube.com/playlist?list=PLsUr39u1DEZao6VtImxLHEU9Zs-ad7wWa" target="_blank" rel="noreferrer noopener" >
              <img src={sable} alt="sable" className="gallery_img" />

              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>

          <div className="gallery">
            <a href="https://youtu.be/lzZVBY8zOqg" target="_blank" rel="noreferrer noopener" >
              <img src={edusesh} alt="edusesh" className="gallery_img" />

              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>

          <div className="gallery">
            <a href="https://youtube.com/playlist?list=PLwfdwmQyZgYjsNFqdey4IupDBcYX3P7PJ" target="_blank" rel="noreferrer noopener" >
              <img src={fast_fast} alt="fast_fast" className="gallery_img" />
              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>

          <div className="gallery">
            <a href="https://www.youtube.com/watch?v=CDZspLrYA2Q" target="_blank" rel="noreferrer noopener" >
              <img src={ndic} alt="ndic" className="gallery_img" />
              <div className="gallery-frame">
                <div className="gallery-frame-child"></div>
              </div>
            </a>

          </div>

        </div>
        <p className="portfolio-text"> WANT TO SEE MORE AMAZING WORKS?</p>
        <div className="portfolio-apps">
          <div className="portfolio-app">
            <a href="https://www.youtube.com/user/NoVexBoss">
              <YouTubeIcon style={{ fontSize: 50, color: "#FDD947" }} />
            </a>
            <span>
              <a href="https://www.youtube.com/user/NoVexBoss">youtube</a>
            </span>
          </div>
          <div className="portfolio-app">
            <a href="https://www.instagram.com/novexboss">
              <InstagramIcon style={{ fontSize: 50, color: "#FDD947" }} />
            </a>
            <span>
              {" "}
              <a href="https://www.instagram.com/novexboss">instagram</a>
            </span>
          </div>
          <div className="portfolio-app">
            <a href="https://vimeo.com/versatilestudiosmedia">
              <FaVimeoV style={{ fontSize: 50, color: "#FDD947" }} />
            </a>
            <span>
              <a href="https://vimeo.com/versatilestudiosmedia">vimeo</a>
            </span>
          </div>
        </div>
        <div className="skills-header">
          <p className="skillheader-text">
            WE ARE PASSIONATE ABOUT SOLVING PROBLEMS WITH CLARITY, SIMPLICITY
            AND HONESTY
          </p>
          <img src={laptop} alt="skillimg" className="skill-img" />
          <div className="skill-bar">
            <p>CHECK OUR SKILLS</p>
            <div className="progress-bar">
              <div className="video">
                <p className="progressbar-text">
                  VIDEO PRODUCTION - <span>15 YEARS EXPERIENCE</span>
                </p>
              </div>
            </div>

            <div className="progress-bar">
              <div className="audio">
                <p className="progressbar-text">
                  AUDIO PRODUCTION - <span>9 YEARS EXPERIENCE </span>
                </p>
              </div>
            </div>

            <div className="progress-bar">
              <div className="marketing">
                <p className="progressbar-text">
                  DIGITAL MARKETING - <span> 7 YEARS EXPERIENCE </span>
                </p>
              </div>
            </div>

            <div className="progress-bar">
              <div className="curation">
                <p className="progressbar-text">
                  MEDIA CURATION - <span>3 YEARS EXPERIENCE </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
