import "./home.css";
import React, { useState } from "react";
import { homeData } from "../data";
// import RemoveIcon from "@mui/icons-material/Remove";

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'


const Home = () => {


  const [slides] = useState(homeData);
  // const [value, setValue] = useState(0);
  // const [swiper, setSwiper] = useState<Swiper>(swiper.slideTo(0));

  // const { imageSrc, description, statement } = slides[value];




  return (
    <div className="home" id="home">
      {/* <div
        className="hero">
        <img src={imageSrc} alt="/" />

        <div className="hero-text">
          <h1>{description}</h1>
          <div className="separator"></div>
          <p>{statement}</p>
        </div>
        <div className="hero-btn">
          {slides.map((item, index) => (
            <RemoveIcon onClick={() => setValue(index)} key={index} />
          ))}
        </div>
      </div> */}

      <Swiper
        pagination={{ clickable: true }}

        // navigation={true}
        spaceBetween={0}
        // onSwiper={(swiper) => setSwiper(swiper)}

        slidesPerView={1}
        direction='horizontal'
        modules={[Pagination]}
        scrollbar={{ draggable: true }}

        className='hero-swiper'>

        {
          slides.map(({ imageSrc, description, statement }, index) => {
            return (
              <SwiperSlide key={index} className='hero'>
                <img src=
                  {imageSrc}
                  alt=""
                />

                <div className="hero-text">
                  <h1>{description}</h1>
                  <div className="separator"></div>
                  <p>{statement}</p>
                </div>


              </SwiperSlide>
            )
          }
          )
        }
      </Swiper>

    </div>
  );
};

export default Home;
