import React from "react";
import "./team.css";
import CountUp from "react-countup";
import promoter1 from "../img/Team-Images/Ajisola-Abiola.png";
import promoter2 from "../img/Team-Images/Akin-Adeyemi.png";
import soundEngineer from "../img/Team-Images/Awele-Mekwunye.png";
import leadEditor from "../img/Team-Images/Ayomide-Kuye.png";
import cameraOperator from "../img/Team-Images/Bolaji-Oginni.png";
import leadMedia from "../img/Team-Images/Idara-Idemeko.png";
import  promoter3  from "../img/Team-Images/Jewku-Ezeike.png";
import digitalMarketer from "../img/Team-Images/Nene-Henshaw.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from "@mui/icons-material/Twitter";

const Team = () => {
  return (
    <>
      <div className="team">
        <div className="section">
          <h1 className="team-header">CREATIVE MINDS</h1>
          <div className="team-section">
            <div className="team-about">
              <p className="team-text">
                We've crafted beautiful content, launched stunning brands, and
                made clients happy for years.
              </p>
              <p className="team-btn">Let's work together</p>
            </div>
            <div className="team-counter">
              <div>
                <span>
                  <CountUp end={10} />
                </span>
                <p>Camera Operators</p>
              </div>
              <div>
                <span>
                  <CountUp end={5} />
                </span>
                <p>Editors</p>
              </div>
              <div>
                <span>
                  <CountUp end={3} />
                </span>
                <p>Writers</p>
              </div>
              <div>
                <span>
                  <CountUp end={3} />
                </span>
                <p>Voice-over Artists</p>
              </div>
              <div>
                <span>
                  <CountUp end={5} />
                </span>
                <p>Digital Marketers</p>
              </div>
            </div>
          </div>
        </div>
       <div className="team-galleries">
       <div className="team-gallery">
          <img src={promoter1} alt="Promoter" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I am Versatile</p>
          </div>
          <div className="overlay">
            <h2>Ajisola Abiola</h2>
            <p> Promoter</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/belosah">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/belosah">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={promoter2} alt="Promoter" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>i get you heard </p>
          </div>
          <div className="overlay">
            <h2>Akin Adeyemi</h2>
            <p>Promoter</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/ak_adeyemi">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/ak_adeyemi">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={soundEngineer} alt="soundEngineer" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I make it Loud </p>
          </div>
          <div className="overlay">
            <h2>Awele Mekwunye</h2>
            <p>Lead Sound Engineer</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/mr_awele">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/mr_awele">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={leadEditor} alt="lead Editor" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I cut it Up</p>
          </div>
          <div className="overlay">
            <h2>Ayomide Kuye</h2>
            <p>Lead Editor</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/callmeslammy">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/callmeslammy">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={cameraOperator} alt="cameraOperator" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I keep it Focused</p>
          </div>
          <div className="overlay">
            <h2>Bolaji Oginni </h2>
            <p> Lead Camera Operator </p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/bolajioginni">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/bolajioginni">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={leadMedia} alt="mediaLead" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I make it Move </p>
          </div>
          <div className="overlay">
            <h2>Idara Idemeko </h2>
            <p> Lead Media Curator </p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/purple_pastry">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/purple_pastry">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={promoter3} alt="Promoter" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>i get you seen </p>
          </div>
          <div className="overlay">
            <h2>Jekwu M. Ezeike </h2>
            <p> Promoter</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/yeyeboyjr">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/buff_daddi/">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>

        <div className="team-gallery">
          <img src={digitalMarketer} alt="digitalMarketer" className="team-img" />
          <div className="overlay-text-wrapper">
            <p>I make Sense</p>
          </div>
          <div className="overlay">
            <h2> Nene Henshaw</h2>
            <p>Lead Digital Marketer</p>
            <div className="overlay-line"></div>
            <div className="team-socials">
              <a href="https://www.twitter.com/nenehenshaw/">
                <TwitterIcon className="socials" />
              </a>
              <a href="https://www.instagram.com/nenehenshaw/">
                <InstagramIcon className="socials" />
              </a>
            </div>
          </div>
        </div>
       </div>
      </div>
    </>
  );
};

export default Team;
