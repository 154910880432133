import React from 'react'
import './footer.css'
import logo from '../img/novex-white.png'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter'; 

const Footer = () => {
  return (
    <>
       <div className='footer'>
        <div>
          <img src={logo} alt="logo" className='footer-logo'/>
          <p className='footer-text'>unplugging africa</p>
          <div className='footer-socials'>
            <a href='https://www.facebook.com/NoVexAfrica'>
              <FacebookOutlinedIcon className='footer-app' style={{ fontSize: 22}}/>
            </a>
            <a href='https://twitter.com/novexboss'>
               <TwitterIcon className='footer-app' style={{ fontSize: 22}}/> 
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
