import "./index.css";
import Header from "./header/Header";
import Home from "./home/Home";
import About from "./about/about";
import Service from "./service/Service";
import Team from "./team/Team";
import Contact from "./contact/Contact";
import Portfolio from "./portfolio/Portfolio";
import Footer from "./footer/Footer";
import React from 'react';


const App = () => {

  return (
    <>
      <Header />
      <Home />
      <About />
      <Service />
      <Portfolio />
      <Team />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
