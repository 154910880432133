import React, { useState } from "react";
import "./contact.css";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { Multiselect } from 'multiselect-react-dropdown'



const Contact = () => {
  /* const [isDropDown, setIsDropDown] = useState(false);

  //list fo dropdown items

  const [itemsList, setItemsList] = useState([
    {
      name: "WHAT ARE YOU INTRESTED IN?",
      value: "what are you intrested in?",
    },
    {
      name: "Video Production",
      value: "video production",
    },
    {
      name: "Audio Production",
      value: "audio production",
    },
    {
      name: "Digital Marketing",
      value: "digital marketing",
    },
    {
      name: "Media Curation",
      value: "media curation",
    },
  ]); */
  const data = [
    { Course: "Video Production", id: 1 },
    { Course: "Audio Production", id: 2 },
    { Course: "Digital Marketing", id: 3 },
    { Course: "Media Curation", id: 4 }

  ]
  const [options] = useState(data);


  //refrence/index to user selected element
  // const [itemIndex, setItemIndex] = useState(null);

  return (
    <>
      <div className="contact">
        <h1 className="contact-header">HOW TO REACH US</h1>
        <div className="contact-section1">
          <p className="contact-text">
            Have a project you're interested in discussing with us? Drop us a
            line below, we’d love to talk.
          </p>
          <div className="contact-info">
            <p>
              {" "}
              <span>
                <PhoneIphoneIcon style={{ fontSize: 35, color: "#9a9a9a" }} />
              </span>{" "}
              +234 817 892 0241
            </p>
            <p>
              {" "}
              <span>
                <DraftsOutlinedIcon
                  style={{ fontSize: 35, color: "#9a9a9a" }}
                />
              </span>{" "}
              info@novexafrica.com
            </p>
          </div>
        </div>
        <div className="contact-section2">
          <div className="contact-container">
            <div className="contact-details">
              <span>OUR STUDIO</span>
              <p>313A Ikorodu Crescent, Ikoyi, Lagos, Nigeria</p>
            </div>
            <div className="contact-details">
              <span>GENERAL ENQUIRIES</span>
              <p>
                <a href='mailto:info@novexafrica.com'>info@novexafrica.com</a>
              </p>
            </div>
            <div className="contact-details">
              <span>CALL US </span>
              <p>+234 817 892 0241</p>
            </div>
          </div>
          <form>
            <input type="text" placeholder="* YOUR NAME" />
            <input type="email" placeholder="* YOUR EMAIL" />
            {/* <div className="custom-dropdown">
              <div
                className={"dropdown-selection" + (isDropDown ? "visible" : "")}
                onClick={(e) => {
                  setIsDropDown(!isDropDown);
                }}
              >
                {itemIndex != null
                  ? itemsList[itemIndex].name
                  : "WHAT ARE YOU INTRESTED IN?"}
              </div>
              {isDropDown ? (
                <div className="items-holder">
                  {itemsList.map((item, index) => (
                    <div
                      key={item.value}
                      className="dropdown-item"
                      onClick={(e) => {
                        setItemIndex(index);
                        setIsDropDown(false);
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div> */}
            <Multiselect options={options} displayValue='Course' className="dropdown-selection"
              placeholder="what are you intrested in" />
            <input type="text" placeholder="YOUR MESSAGE" className="text" />
          </form>
        </div>
        <button>submit</button>
      </div>
    </>
  );
};

export default Contact;
