import React, { useState } from "react";
import "./header.css";
import Logo from "../img/novex-white.png";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-scroll'


const Header = () => {
  //setting mobile nav
   const [click, setClick] = useState(false)
   const handleClick = () => setClick(!click)
  

  //change nav color when scrolling
   const [color, setColor] = useState(false)
   const changeColor = () =>{
    if (window.scrollY >= 10){
      setColor(true)
    }else{
      setColor(false)
    }
   }

   window.addEventListener('scroll' ,changeColor)

  return (
    <div>
      <div className={color ? 'header header-bg' : 'header'}>

        

            <div id="lgmen">
              <div className="logo">
                <Link to="home">
                 <img src={Logo} alt="logo" className="logoimg"/>
                </Link>
              </div>

              <ul className={click ? 'header-menu active' : 'header-menu'}>
              <li className="header-item">
                <Link to="about" spy={true}smooth={true} offset={-100} duration={100}>About</Link>
                </li>
              <li className="header-item">
              <Link to="service" spy={true}  smooth={true} offset={-100} duration={100}>Service</Link>
                </li>
              <li className="header-item">
              <Link to="portfolio" spy={true}  smooth={true} offset={-100} duration={100}>Portfolio</Link>
                </li>
              <li className="header-item">
              <Link to="team" spy={true}  smooth={true} offset={-100} duration={100}>Team</Link>
                </li>
              <li className="header-item">
              <Link to="contact" spy={true}  smooth={true} offset={-100} duration={100}>Contact</Link>
                </li>
             </ul>

            </div>

         <div className="header-menu menu">
              <p>let's work together?</p>
             <Link to='contact' spy={true}  smooth={true} offset={-100} duration={100}>
             <button>START NOW</button>
             </Link>
          </div>
          <div className="hamburger" onClick={handleClick}>
            {click ? (<CloseIcon /> ) : ( <DensityMediumIcon />)}
          </div>  
      </div> 
     
        
    </div>
  );
};

export default Header;
